.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}


.App-link {
  color: #09d3ac;
}

.logo {
  border: 0;
  max-width: 600px;
  max-height: 120px;
  padding-top: 20px;
  padding-bottom: 20px;

}

.stepsContainer {
  margin-top: 25px;
  margin-bottom: 10px;
}

.steps {
  font-size: 1.35em;
  margin-top:10px;
  margin-bottom:35px;
  line-height: 1.39em;
  font-weight: bold;
}

.iconLink {
  margin-top: 25px;
  margin-bottom: 10px;
  padding-left: 5px;
  height: 100%;
}

.customerCare {
  color: #b31f23;
  text-decoration: none;
}

.privacyPolicy {
  color: #b31f23;
  text-decoration: none;
}

strong.highlight {
  color: #b31f23;
}

a.hover {
  cursor: pointer;
}

.fourOhFour {
  height: 200px;
  width: 400px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -200px;
}

.text404 {
  color: black;
  width: 100%;
  height: 100%;
}

.download {
  font-size: 1.35em;
  margin-top: 10px;
  margin-bottom: 35px;
  line-height: 1.39em;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


